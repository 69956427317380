.auf-rate{
    .ant-rate-star{
        .ant-rate-star-second{
            color: var(--theme-bg-primary);
        }
        &.ant-rate-star-full{
            .ant-rate-star-second{
                color: var(--theme-nav-link-primary)
            }
        }
    }
}