.auf-form{
    .ant-form-item-required{
        color: var(--theme-bg-reverse) !important;
    }
    .ant-input-affix-wrapper{
        background-color: var(--theme-bg-primary);
        border-color: $gray;
        &:hover{
            border-color: var(--theme-nav-link-primary);
        }
        &-focused{
            border-color: var(--theme-nav-link-primary);
            box-shadow: none;
        }
        .ant-input{
            // color: var(--theme-bg-reverse) !important;
            box-shadow: none;
        }
    }
    input{
        background-color: var(--theme-bg-primary) !important;
        border-color: $gray;
        color: var(--theme-bg-reverse);
        &:hover{
            border-color: var(--theme-nav-link-primary);
        }
        &:focus{
            border-color: var(--theme-nav-link-primary);
            box-shadow: none;
        }
    }
    .ant-input-suffix{
        .anticon{
            color: var(--theme-bg-reverse);
            &:hover{
                color:var(--theme-nav-link-primary);
            }
        }
    }
}