.auf-tabs{
    .ant-tabs-nav{
        &::before{
            border-color: var(--theme-bg-reverse);
        }
    }
    .ant-tabs-tab{
        .ant-tabs-tab-btn{
            color: var(--theme-bg-reverse);
            text-transform: capitalize;
            &:hover{
                color: var(--theme-nav-link-primary);
            }
        }
        &.ant-tabs-tab-active{
            .ant-tabs-tab-btn{
            color: var(--theme-nav-link-primary);
        }
    }
    }
    .ant-tabs-ink-bar{
        background-color: var(--theme-nav-link-primary);
    }
    .ant-tabs-content{
        .ant-tabs-tabpane{
            color: var(--theme-bg-reverse);
        }
    }
}