// Applied to the flex container
.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.flex-row {
  flex-direction: row;
}

// default
.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.justify-start {
  justify-content: flex-start;
}

// default
.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.flex-align-stretch {
  align-items: stretch;
}

// default
.align-center {
  align-items: center;
}

.align-start {
  align-items: start;
}

.align-end {
  align-items: end;
}

.align-baseline {
  align-items: baseline;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

// default
.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

// fit element based on the available space
.flex-fit {
  min-width: 0;
  flex: 1 1 0%;
}
