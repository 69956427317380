.auf-checkbox,.ant-checkbox-wrapper{
   display: flex;
   align-items: flex-start;
   height: fit-content;
    .ant-checkbox-checked{
        .ant-checkbox-inner{
           background-color: var(--theme-nav-link-primary);
           border-color: var(--theme-nav-link-primary);
        }
    }
    .ant-checkbox-inner{
background-color: var(--theme-bg-primary);
border-color: var(--theme-bg-reverse);
   }
 span{
   color: var(--theme-bg-reverse);
 }   
.ant-checkbox-indeterminate{
   .ant-checkbox-inner{
      &::after{
background-color: var(--theme-nav-link-primary);
      }
   }
}

}

.ant-checkbox-wrapper{
   &:hover{
      .ant-checkbox-inner{
         border-color: var(--theme-nav-link-primary) !important;
      }
      .ant-checkbox-checked{
         .ant-checkbox-inner{
            background-color: var(--theme-comp-hover) !important;
         }
      }
    }
}