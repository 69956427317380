// font family
.font-family-primary {
  font-family: $base-font-family;
}

// Font
.font-body-xs {
  font-weight: 400;
  font-size: rem(12);
  color: var(--black);
  line-height: 1.3;
}

.font-body-normal {
  font-weight: 400;
  font-size: rem(13);
  color: var(--black);
  line-height: 1.3;
}

.font-body-small {
  font-weight: 400;
  font-size: rem(14);
  color: var(--black);
  line-height: 1.3;
}

.font-body-medium {
  font-weight: 500;
  font-size: rem(16);
  color: var(--black);
  line-height: 1.3;
}

.font-title-xs {
  font-weight: 500;
  font-size: rem(18);
  color: var(--black);
  line-height: 1.3;
}

.font-title-small {
  font-weight: 600;
  font-size: rem(26);
  color: var(--black);
  line-height: 1.3;
}

.font-title-medium {
  font-weight: 600;
  font-size: rem(35);
  color: var(--black);
  line-height: 1.3;
}

// Font transform
.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

// Font Weights
.font-weight-300 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

// Line Heights
.line-height-12 {
  line-height: 1.2
}

.line-height-13 {
  line-height: 1.3
}

.line-height-14 {
  line-height: 1.4
}

.line-height-15 {
  line-height: 1.5
}

// colors
.font-color-primary {
  color: var(--black);
}

.font-color-secondary {
  color: var(--black);
}

.font-color-tertiary {
  color: var(--secondary);
}

.font-color-accent {
  color: var(--primary-dark);
}

.text-white{
  color: $white !important;
}