.auf-collapse{
    width: 100%;
    background-color: var(--theme-bg-primary);
    .ant-collapse-item{
        border-color: var(--theme-bg-reverse);
    }
    .ant-collapse-arrow{
color: var(--theme-bg-reverse) !important;
    }
    .ant-collapse-header-text{
        color: var(--theme-bg-reverse);
    }
    .ant-collapse-content{
        border-color: var( --theme-box-bg) !important;
        background-color: var(--theme-bg-primary);
        .ant-collapse-content-box{
            p{
                color: var(--theme-bg-reverse);
            }
        }
    }
}