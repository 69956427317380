@import './assets/base/index','./assets/helpers/index','./assets/themes/themes.scss','./assets/utilities/index';


// button
@import './Components/Elements/Button/button.scss';
// toggle
@import './Components/Elements/Toggle/toggle.scss';
// tooltip
@import './Components/Elements/Tooltip/tooltip.scss';
// checkbox
@import './Components/Elements/Checkbox/checkbox.scss';
// collapse
@import './Components/Elements/Collapse/collapse.scss';
// radio
@import './Components/Elements/Radio/radio.scss';
// upload
@import './Components/Elements/Upload/upload.scss';
// popup
@import './Components/Elements/Popup/popup.scss';
// date picker
@import './Components/Elements/DatePicker/date_picker.scss';
// card
@import './Components/Elements/Card/card.scss';
// table
@import './Components/Elements/Table/table.scss';
// drawer
@import './Components/Elements/Drawer/drawer.scss';
// search
@import './Components/Elements/SearchBox/search.scss';
// form
@import './Components/Elements/Form/form.scss';
// date picker
@import './Components/Elements/TimePicker/time_picker.scss';
// tabs
@import './Components/Elements/Tab/tab.scss';
// Breadcrumb
@import './Components/Elements/Breadcrumb/breadcrumb.scss';
// tag
@import './Components/Elements/Tag/tag.scss';
// Rate
@import './Components/Elements/Rate/rate.scss';
// select
@import './Components/Elements/Select/select.scss';
// slider
@import './Components/Elements/Slider/slider.scss';




::-webkit-scrollbar {
    width: 7px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: $black; 
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: $gray; 
    border-radius: 5px;
  }