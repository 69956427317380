.auf-button.ant-btn-primary,.ant-btn-default.auf-button.ant-btn-primary{
background-color: var(--theme-nav-link-primary);
color: var(--theme-bg-primary);
&:hover{
    background-color: var(--theme-comp-hover) !important;
    color: var(--theme-bg-primary) !important;
}
}

.auf-button.ant-btn-text{
    color: var(--theme-bg-reverse);
    &:hover{
        background-color: var(--theme-nav-link-primary) !important;
        color: var(--theme-bg-primary) !important;
    }
}

.auf-button.ant-btn-link{
color: var(--theme-nav-link-primary);

&:hover{
    color: var(--theme-comp-hover) !important;
}
}

.auf-button.ant-btn-default, .auf-button.ant-btn-circle{
background-color: var(--theme-bg-primary);
color: var(--theme-bg-reverse);
border-color: var(--theme-nav-link-secondary);
&:hover{
    border-color: var(--theme-nav-link-primary) !important;
    color: var(--theme-nav-link-primary) !important;
}
.ant-btn-icon{
    svg{
        circle{
            stroke: var(--theme-bg-reverse);
        }
        path{
            stroke: var(--theme-bg-reverse);
        }
    }
}
}

.auf-button.ant-btn-background-ghost{
background-color: transparent;
color: var(--theme-nav-link-primary);
border-color: var(--theme-nav-link-primary);
&:hover{
    background-color: transparent !important;
    color: var(--theme-nav-link-primary) !important;
    border-color: var(--theme-nav-link-primary) !important;
}
}

.auf-button.ant-btn-dangerous{
    background-color: var(--theme-bg-primary);
    border-color: #ff4d4f;
    color: #ff4d4f;
}



