.auf-time-picker{
    border-color: $gray;
    background-color: var(--theme-bg-primary);
    &:hover{
        border-color: var(--theme-nav-link-primary);
    }
    .ant-picker-input{
        input{
            color: var(--theme-bg-reverse);
            &::placeholder{
                color: var(--theme-bg-reverse);
            }
        }
    }
    .ant-picker-suffix{
        .anticon{
            color: var(--theme-bg-reverse);
        }
    }
    &.ant-picker-focused{
        border-color: var(--theme-nav-link-primary);
        box-shadow: none;
    }
    .ant-picker-clear{
        background-color: var(--theme-bg-primary);
    }
    .ant-picker-active-bar{
        background-color: var(--theme-nav-link-primary);
    }
}