// styles.scss
@import '../assets/themes/themes.scss';
// Main Container Styles
.main-container {
  display: flex;
  height: 100vh;
}

// Side Panel Styles
.side-panel {
  background-color: var(--theme-bg-primary);
  color: var(--theme-bg-primary);
  // padding: 20px 0 20px 20px;
  padding: 32px 0 12px 12px;
  // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  min-width: 244px;
  max-width: 244px;
  border-right: 1px solid var(--theme-bg-reverse);
}

.panel-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.logo {
  margin: 0 auto;
}

// Navigation Styles
.nav {
  overflow-y: auto;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-right: 12px;
  margin-top: 20px;
}

.nav-link {
  padding: 8px 5px;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color .4s ,color .4s;
  color: var(--theme-bg-reverse);
  font-weight: 400;
  line-height: 1.3;
  font-size: 14px;
  &:hover {
    background-color: var(--theme-nav-link-primary);
    color: $white;
  }

  &.active{
    background-color: var(--theme-nav-link-primary);
    color: $white;
  }
}

// Main Content Styles
.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

// Header Styles
.header {
  height: 60px;
  background-color: var(--theme-bg-primary);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px 0 20px;
}
.comp-title{
  text-transform: capitalize;
  font-weight:600 ;
  font-size: 30px;
  color: var(--theme-bg-reverse);
}


// Content Container Styles
.content-container {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  background-color: var(--theme-bg-primary);
}

.content {
display: flex;
flex-direction: column;
gap: 24px;
}

.comp-title-name{
  color: var(--theme-bg-reverse);
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
}