.auf-toggle.ant-switch{
background-color: var(--theme-nav-link-secondary);
&.ant-switch-checked{
    background-color: var(--theme-nav-link-primary);

    &:hover{
        background-color: var(--theme-comp-hover) ;
    }
}
    .ant-switch-handle{
&::before{
  background-color: $white;
}
    }
    .ant-switch-inner-checked{
        margin-top: 3px;
    }
    .ant-switch-inner-unchecked{
        margin-top: -15px;
    }
}