$white:#fff;
$black:#000;
$gray:rgba(190, 190, 190, 0.651);

:root { 
  &[data-theme="light"]{
    --theme-bg-primary:#F2F2F2;
    --theme-bg-reverse:#414141;
    --theme-nav-border:#6b94ff;
    --theme-nav-link-primary: #f25d7d;
    --theme-nav-link-secondary: #25282c;
    --theme-box-bg:rgba(190, 190, 190, 0.619);
    --theme-comp-hover:#ea335a;
    --theme-comp-title:#151515;
  }

  &[data-theme="dark"] {
    --theme-bg-primary:#414141;
    --theme-bg-reverse:#F2F2F2;
    --theme-nav-border:#6b94ff;
    --theme-nav-link-primary: #f25d7d;
    --theme-nav-link-secondary: #9e9e9e;
    --theme-box-bg:#494949;
    --theme-comp-hover:#ea335a;
    --theme-comp-title:#151515;

  }
}