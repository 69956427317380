.auf-upload{
    .ant-btn{
        background-color: var(--theme-bg-primary);
        border-color: $gray;
        color: var(--theme-bg-reverse);
        &:hover{
            border-color: var(--theme-nav-link-primary) !important;
            color: var(--theme-nav-link-primary) !important;
        }
    }
}

.ant-upload-drag{
    border-color: var(--theme-bg-reverse) !important;
    &:hover{
        border-color: var(--theme-nav-link-primary) !important;
    }
    .ant-upload.ant-upload-btn{
padding: 16px;
    }
    .anticon.anticon-inbox{
            color: var(--theme-nav-link-primary) !important;
    }
    .ant-upload-text{
        color: var(--theme-bg-reverse) !important;
    }
    .ant-upload-hint{
        color: var(--theme-bg-reverse) !important;
    }
        }