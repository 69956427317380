.theme-drop{
    padding: 5px 16px;
    background-color: var(--theme-nav-link-primary);
    border-color: transparent;
    color: $white;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color .4s;
  
    &:hover{
      background-color: var(--theme-comp-hover);
    }
  }

  .auf-message{
    .ant-message-notice-content{
      background-color: var(--theme-bg-primary) !important;
    }
    .ant-message-custom-content{
      display: flex;
      align-items: center;
      span{
        color: var(--theme-bg-reverse);
      }
    }
  }