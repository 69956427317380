.auf-modal{
    .ant-modal-content{
        background-color: var(--theme-bg-primary);
    }
    .ant-modal-close{
        &:hover{
            background-color: $gray;
        }
        .anticon.anticon-close{
            color: var(--theme-bg-reverse);
        }
    }
    .ant-modal-header{
        background-color: transparent;
    }
    .ant-modal-title{
        color: var(--theme-bg-reverse);
    }
    .ant-modal-body{
        p{
            color: var(--theme-bg-reverse);
        }
    }
    .ant-modal-footer{
        .ant-btn-default{
            background-color: var(--theme-bg-primary);
color: var(--theme-bg-reverse);
border-color: var(--theme-nav-link-secondary);
&:hover{
    border-color: var(--theme-nav-link-primary) !important;
    color: var(--theme-nav-link-primary) !important;
}
        }
        .ant-btn-primary{
            background-color: var(--theme-nav-link-primary);
            color: var(--theme-bg-primary);
            &:hover{
                background-color: var(--theme-comp-hover) !important;
                color: var(--theme-bg-primary) !important;
            }
        }
    }


    .ant-modal-confirm-title{
color: var(--theme-bg-reverse);
    }
    .ant-modal-confirm-content{
color: var(--theme-bg-reverse);
    }
    .ant-modal-confirm-btns{
            .ant-btn-primary{
                background-color: var(--theme-nav-link-primary);
                color: var(--theme-bg-primary);
                &:hover{
                    background-color: var(--theme-comp-hover) !important;
                    color: var(--theme-bg-primary) !important;
                }
            }
    }
}