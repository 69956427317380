.auf-radio{
    height: fit-content;
    &.ant-radio-wrapper{
        span{
            color: var(--theme-bg-reverse);
        }
        .ant-radio-inner{
            background-color: var(--theme-bg-primary);
            border-color: var(--theme-bg-reverse);
        }
        .ant-radio-checked{
            .ant-radio-inner{
                background-color: var(--theme-nav-link-primary);
                border-color: var(--theme-nav-link-primary);

                &::after{
                    background-color: var(--theme-bg-primary);
                }
            }
            &::after{
                border-color: transparent !important;
            }
        }

        &:hover{
            .ant-radio-inner{
                border-color: var(--theme-nav-link-primary);
            }

        }
    }
&.ant-radio-group-outline{

    .ant-radio-button-wrapper{
        background-color: var(--theme-bg-primary);
        color: var(--theme-bg-reverse);
        &.ant-radio-button-wrapper-checked{
            border-color: var(--theme-nav-link-primary);
    &::before{
    background-color: var(--theme-nav-link-primary) ;
}
            span{
    color: var(--theme-nav-link-primary);
            }
        }
        &:hover{
            span{
                color: var(--theme-nav-link-primary);
            }
        }
    }
}
&.ant-radio-group-solid{
    .ant-radio-button-wrapper{
        background-color: var(--theme-bg-primary);
        color: var(--theme-bg-reverse);
        &.ant-radio-button-wrapper-checked{
            background-color: var(--theme-nav-link-primary);
            border-color: var(--theme-nav-link-primary);
            color: var(--theme-bg-primary) !important;

            &::before{
                background-color: var(--theme-nav-link-primary);
            }
        }
        &:hover{
                color: var(--theme-nav-link-primary);
        }
    }
}
.ant-radio-button-wrapper-disabled{
    background-color: $gray !important;
}
}