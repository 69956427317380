.auf-drawer{
    background-color: var(--theme-bg-primary) !important;
    .ant-drawer-header{
        border-color: $gray;
    }
    .ant-drawer-close{
        color: var(--theme-bg-reverse);
        &:hover{
            color: var(--theme-nav-link-primary);
        }
    }
    .ant-drawer-title{
        color: var(--theme-bg-reverse);
    }
    .ant-drawer-body{
        p{
            color: var(--theme-bg-reverse);
        }
    }
}