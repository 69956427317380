.auf-breadcrumb{
    .ant-breadcrumb-link{
        color: var(--theme-bg-reverse);
    }
    .ant-breadcrumb-separator{
        color: var(--theme-bg-reverse);
    }
    .ant-breadcrumb-link{
        a{
            color: var(--theme-bg-reverse);
            &:hover{
                background-color: var(--theme-nav-link-primary);
            }
        }
    }
}