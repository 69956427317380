.auf-datepicker{
    background-color: var(--theme-bg-primary);
    border-color: $gray;
    &:hover{
        border-color: var(--theme-nav-link-primary);
    }
    .anticon.anticon-calendar
    {
        color: var(--theme-bg-reverse);
    }
    .ant-picker-input{
input{
    color: var(--theme-bg-reverse);
    &::placeholder{
        color: var(--theme-bg-reverse);
    }
}
    }

    &.ant-picker-focused{
        border-color: var(--theme-nav-link-primary);
        box-shadow: none;
    }
    .ant-picker-clear{
        background-color: var(--theme-bg-primary);
    }
    .ant-picker-active-bar{
        background: var(--theme-nav-link-primary);
    }
}

.auf-datepicker-popup{
    .ant-picker-panel{
        background-color: var(--theme-bg-primary) !important;
        border-color: $gray;
    }
    .ant-picker-header{
        color: var(--theme-bg-reverse);
        border-color: $gray;

    }
    .ant-picker-month-btn,.ant-picker-year-btn,.ant-picker-decade-btn{
        &:hover{
            color: var(--theme-nav-link-primary) !important;
        }
    }
    .ant-picker-header-super-prev-btn,.ant-picker-header-prev-btn,.ant-picker-header-next-btn,.ant-picker-header-super-next-btn{
        color: var(--theme-bg-reverse) !important;
        &:hover{
            color: var(--theme-nav-link-primary) !important;
        }
    }
    .ant-picker-content{
        thead{
            tr{
                th{
                    color: var(--theme-bg-reverse);
                }
            }
        }
        tbody{
            tr{
                .ant-picker-cell.ant-picker-cell-in-view{
                    color: var(--theme-bg-reverse);
                }
                .ant-picker-cell{
                    color: $gray;
                }
                td{
                    .ant-picker-cell-inner{
                        &:hover{
                            background-color: $gray !important;
                        }
                    }
                }
                .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-today{
                    .ant-picker-cell-inner{

                &::before{
                    border-color: var(--theme-nav-link-primary);
                }
                    }
                }
                .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-selected{
                    .ant-picker-cell-inner{
                        background-color: var(--theme-nav-link-primary) !important;
                    }
                }
                .ant-picker-cell-range-start{
                    .ant-picker-cell-inner{
                        background-color: var(--theme-nav-link-primary) !important;
                    }
                }
                .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range,.ant-picker-cell-in-view.ant-picker-cell-range-start,.ant-picker-cell-in-view.ant-picker-cell-range-end{
&::before{
    background-color: $gray !important;
}
                }
            }
        }
    }
    .ant-picker-today-btn{
        color: var(--theme-nav-link-primary);
        &:hover{
            color: var(--theme-comp-hover);
        }
    }
    .ant-picker-week-panel-row.ant-picker-week-panel-row-selected{
        .ant-picker-cell{
            &::before{
                background: var(--theme-nav-link-primary) !important;
            }
        }
    }
    .ant-picker-week-panel-row{
&:hover{
    .ant-picker-cell{
        &::before{
            background: $gray;
        }
    }
}
    }
.ant-picker-cell-end.ant-picker-cell-in-view.ant-picker-cell-range-end,.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-end{
    .ant-picker-cell-inner{
        background-color: var(--theme-nav-link-primary) !important;
    }
}
.ant-picker-cell-in-view.ant-picker-cell-in-range{
    &:hover{
        &::before{
            background-color: red !important;
        }
    }
}
.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-end-single.ant-picker-cell-selected,.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-start-single{
    &::before{
        background-color: transparent !important;
    }
}
}