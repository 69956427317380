.auf-tag{
    display: flex;
    align-items: center;
    background-color: var(--theme-bg-primary);
    color: var(--theme-bg-reverse);
    border-color: $gray;
    font-size: 13px;
    a{
    font-size: 13px;
    color: var(--theme-bg-reverse);

    &:hover{
        color: var(--theme-nav-link-primary);
    }
    }
    .anticon{
        color:var(--theme-bg-reverse);

        &:hover{
            color: var(--theme-nav-link-primary);
        }
    }
}