.auf-select{
    .ant-select-selector{
        background-color: var(--theme-bg-primary) !important;
        border-color: var(--theme-bg-reverse) !important;
        &:hover{
            border-color: var(--theme-nav-link-primary) !important;
        }
    }
    .ant-select-selection-item{
        color: var(--theme-bg-reverse);
    }
    .ant-select-arrow{
        color: var(--theme-bg-reverse)
    }
    &.ant-select-focused{
        .ant-select-selector{
            border-color: var(--theme-nav-link-primary) !important;
        }
        .ant-select-selection-item{
            color:var(--theme-bg-reverse);
        }
    }
&.ant-select-multiple{
    .ant-select-selection-item{
        background-color: var(--theme-bg-reverse);
    }
    .ant-select-selection-item-content{
        color: var(--theme-bg-primary);
    }
    .ant-select-selection-item-remove{
        color: var(--theme-bg-primary);
        &:hover{
            color: var(--theme-nav-link-primary);
        }
    }
    .ant-select-clear{
        top: 45%;
        width: 16px;
        height: 16px;
    background-color: var(--theme-bg-primary);
    }
}
}

.auf-select-popup{
    // display: block !important;
    background-color: var(--theme-bg-primary);
    border: 1px solid var(--theme-bg-reverse);
    .ant-select-item.ant-select-item-option{
        &:hover{
            background-color: $gray;
        }
    }
    .ant-select-item-option-content{
        color: var(--theme-bg-reverse);
    }
    .ant-select-item.ant-select-item-option.ant-select-item-option-selected{
        background-color: var(--theme-nav-link-primary);
    }
}