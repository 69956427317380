.auf-card{
    border-color: $gray;
    color: var(--theme-bg-reverse);
    background-color: var(--theme-bg-primary);
    &:hover{
        box-shadow: none;
        border-color: $gray;
    }
    .ant-card-head{
        border-color: $gray;
    }
    .ant-card-head-title{
        color: var(--theme-bg-reverse);
    }
    .ant-card-body{
        max-height: 450px;
        overflow-y: auto;
    }
    .ant-card-extra{
       a{
        color: var(--theme-nav-link-primary);

        &:hover{
            color: var( --theme-comp-hover);
        }
       }
    }
    .ant-card-meta-title,.ant-card-meta-description{
        color: var(--theme-bg-reverse);
    }
}