$black: #000;
$white: #fff;

// black
$black-100:#17232A;

// blue
$blue-60:#C9E7F2;

// gray
$gray-80:#666666;
$gray-70:#8F9091;
$gray-30:#EAEBEC;
$gray-10:#F6F6F6;

// red
$red-90:#D02326;

// pink
$pink-20:#FFEDED;