@import '../assets/themes/themes.scss';
.comp-wrapper{
    border-radius: 0.75rem;
    background-color: var(--theme-box-bg);
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.title-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 30px 16px 16px;
    background-color: var(--theme-comp-title);
}
.comp-title{
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.75rem;
text-transform: capitalize;
    color: $white;
}
.action-container{  
display: flex;
align-items: center;
gap: 8px;
cursor: pointer;
&:hover{
    .code-title{
        color: var(--theme-nav-link-primary);
    }
    .code-icon{
        svg{
            path{
                stroke: var(--theme-nav-link-primary);
            }
        }
    }
}
}
.code-icon{
    svg{
        path{
           transition: stroke .4s;
           stroke: $white;
        }
    }
}
.code-title{
    font-weight: 400;
    font-size: 16px;
    line-height: 1;
text-transform: capitalize;
    color: $white;
    transition: color .4s;
}
.children-container{
    display: flex;
    justify-content: left;
    min-height: 80px;
    padding: 16px 12px;
}

.copy-icon{
    display: block;
    padding: 3px;
    border-radius: 4px;
    transition: background-color .4s;
    cursor: pointer;
    &:hover{
        background-color: $gray;
    }
    svg{
        path{
            stroke:var(--theme-bg-reverse);
        }
    }
}