.auf-search{
    .ant-input{
        background-color: var(--theme-bg-primary);
        color: var(--theme-bg-reverse);
border-color: $gray;

&:hover{
    border-color: var(--theme-nav-link-primary);
}
        &::placeholder{
            color: $gray;
        }
        &:focus{
            border-color: var(--theme-nav-link-primary);
            box-shadow: none;
        }
    }

    .ant-input-search-button{
        color: var(--theme-bg-reverse) !important;
        background-color: var(--theme-bg-primary);
        &:hover{
            border-color: var(--theme-nav-link-primary) !important;
            color: var(--theme-nav-link-primary) !important;
        }
    }
    .ant-input-affix-wrapper{
        background-color: var(--theme-bg-primary);
        border-color: $gray;
        &.ant-input-affix-wrapper-focused{
            border-color: var(--theme-nav-link-primary);
            box-shadow: none;
        }
        &:hover{
            border-color: var(--theme-nav-link-primary);
        }

    }
    .ant-input-group-addon{
        background-color: var(--theme-bg-reverse);
        border-color: $gray;
        color: var(--theme-bg-primary);
    }
    &.ant-input-search-with-button{
        .ant-input-search-button{
            background-color: var(--theme-nav-link-primary);
color: var(--theme-bg-reverse) !important;
            &:hover{
                background-color: var(--theme-comp-hover);
            }
        }
    }
    .ant-input-suffix{
        .anticon.anticon-audio{
            color: var(--theme-bg-reverse) !important;
        }
    }
}