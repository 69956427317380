.auf-table{
    .ant-table{
        background-color: var(--theme-bg-primary);
        color: var(--theme-bg-reverse);
    }
    .ant-table-cell{
        background-color: var(--theme-bg-primary);
        border-color: var(--theme-bg-reverse) !important;
        color: var(--theme-bg-reverse);
        a{
            color: var(--theme-nav-link-primary);
        }
        &.ant-table-cell-row-hover{
            background-color: $gray !important;
        }
    }
    .ant-table-thead{
        tr{
            th{
                background-color: var(--theme-bg-primary);
        color: var(--theme-bg-reverse);

        &::before{
            background-color: var(--theme-bg-reverse) !important;
        }
            }
        }
    }
    .ant-table-tbody{
        tr{
            td{
                
            }
        }
    }
    .anticon.anticon-left,.anticon-right{
        color: $gray;
    }
    .ant-pagination-item{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ant-pagination-item.ant-pagination-item-active{
        display: flex;
        align-items: center;
        justify-content: center;
        border-color: var(--theme-nav-link-primary);
        a{
            color: var(--theme-nav-link-primary);
        }
    }
}