.d-block {
  display: block;
}

.d-none {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}
