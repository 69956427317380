.btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	font-weight: 600;
	line-height: 1.3;
	text-align: center;
	vertical-align: middle;
	padding: 16px 24px;
	border: 1px solid transparent;
	border-radius: $border-radius;
	cursor: pointer;
	font-size: rem(16);
	user-select: none;
	transition: all 0.3s ease-in-out;
	&:disabled,
	&.disabled,
	&[aria-disabled='true'] {
		cursor: default;
	}
	svg {
		* {
			transition: all .4s;
		}
	}


}
