.auf-tooltip{
   color: var(--theme-bg-reverse);
}

.auf-tooltip-overlay{
    .ant-tooltip-content{
        .ant-tooltip-inner{
background-color: var(--theme-bg-reverse);
            color: var(--theme-bg-primary);
        }
    }
    .ant-tooltip-arrow{
        --antd-arrow-background-color:var(--theme-bg-reverse);
    }
}
