.auf-slider{
    .slick-dots{
        li{
            &:hover{
                button{
                    background-color: var(--theme-bg-reverse);
                }
            }
            &.slick-active{
                button{
                    background-color: var(--theme-nav-link-primary);
                }
            }
        }
    }
}